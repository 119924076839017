<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Customers/Exports</h4>
          <div>
            <router-link :to="can('customer-create') ? {name:'appCustomerCreate'} : '#'">
              <span class="glow d-flex align-items-center"
                    :title="can('customer-create') ? 'Create' : 'Create Forbidden' ">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create
              </span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Customer Type</label>
                  <VueMultiselect v-model="selectedCustomerType"
                                  :options="customerTypeOptions" :close-on-select="true"
                                  placeholder="Select Customer Type" label="name" track-by="name"
                                  :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Status</label>
                  <VueMultiselect v-model="selectedStatus"
                                  :options="statusOptions" :close-on-select="true"
                                  placeholder="Select Status" label="name" track-by="name"
                                  :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="from-group">
                  <label>Newsletter Subscription</label>
                  <VueMultiselect v-model="selectedNewsletterSubscriptionStatus"
                                  :options="newsletterSubscriptionStatusOptions" :close-on-select="true"
                                  placeholder="Select Status" label="name" track-by="name"
                                  :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="from-group">
                  <label>State</label>
                  <VueMultiselect v-model="selectedState"
                                  :options="stateOptions" :close-on-select="true"
                                  label="name" track-by="name"
                                  :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Create Date From</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedCreatedDateFrom" :update-on-input="true"
                                  :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Create Date To</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedCreateDateTo" :update-on-input="true"
                                  :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-10">
                <div class="form-group">
                  <label>Customer Or Address Search</label>
                  <input v-model="customerSearchText"
                         class="form-control" type="text"
                         placeholder="Search by Customer or Address">
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label style="visibility: hidden">Filter & Preview</label>
                  <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                          @click="applyCustomerFilter">Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <h3>Export Columns</h3>
            <div class="row align-items-center ">
              <div class="col-6 col-sm-3 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.user" class="form-check-input"
                         type="checkbox"
                         id="firstNameCheckbox" value="first_name">
                  <label class="form-check-label" for="firstNameCheckbox">First Name</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.user" class="form-check-input"
                         type="checkbox"
                         id="lastNameCheckbox" value="last_name">
                  <label class="form-check-label" for="lastNameCheckbox">Last Name</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.user" class="form-check-input"
                         type="checkbox"
                         id="fullNameCheckbox" value="full_name">
                  <label class="form-check-label" for="fullNameCheckbox">Full Name</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.user" class="form-check-input"
                         type="checkbox"
                         id="emailCheckbox" value="email">
                  <label class="form-check-label" for="emailCheckbox">Email</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.user" class="form-check-input"
                         type="checkbox"
                         id="phoneNumberCheckbox" value="phone_number">
                  <label class="form-check-label" for="phoneNumberCheckbox">Phone Number</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.user" class="form-check-input"
                         type="checkbox"
                         id="dateOfBirthCheckbox" value="date_of_birth">
                  <label class="form-check-label" for="dateOfBirthCheckbox">Date of Birth</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.customer" class="form-check-input"
                         type="checkbox"
                         id="customerTypeCheckbox" value="type">
                  <label class="form-check-label" for="customerTypeCheckbox">Customer Type</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.customer" class="form-check-input"
                         type="checkbox"
                         id="newsletterSubscriptionCheckbox" value="newsletter_subscription">
                  <label class="form-check-label" for="newsletterSubscriptionCheckbox">Newsletter Subscription</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.customer" class="form-check-input"
                         type="checkbox"
                         id="createdAtCheckbox" value="created_at">
                  <label class="form-check-label" for="createdAtCheckbox">Created At</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.address" class="form-check-input"
                         type="checkbox"
                         id="streetCheckbox" value="street">
                  <label class="form-check-label" for="streetCheckbox">Street</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.address" class="form-check-input"
                         type="checkbox"
                         id="suburbCheckbox" value="suburb">
                  <label class="form-check-label" for="suburbCheckbox">Suburb</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.address" class="form-check-input"
                         type="checkbox"
                         id="stateCheckbox" value="state">
                  <label class="form-check-label" for="stateCheckbox">State</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.address" class="form-check-input"
                         type="checkbox"
                         id="postCodeCheckbox" value="post_code">
                  <label class="form-check-label" for="postCodeCheckbox">Post Code</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.address" class="form-check-input"
                         type="checkbox"
                         id="countryCheckbox" value="country">
                  <label class="form-check-label" for="countryCheckbox">Country</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.address" class="form-check-input"
                         type="checkbox"
                         id="fullAddressCheckbox" value="full_address">
                  <label class="form-check-label" for="fullAddressCheckbox">Full Address</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportCustomerListParams.selected_columns.business" class="form-check-input"
                         type="checkbox"
                         id="businessNameCheckbox" value="name">
                  <label class="form-check-label" for="businessNameCheckbox">Business Name</label>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-end">
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label style="visibility: hidden">Export Data</label>
                  <button :disabled="!(customers.length && hasSelectedColumns)" type="button"
                          class="btn btn-primary btn-block glow users-list-clear mb-0"
                          @click="exportCustomers()">Export Data
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'20%'}">
                      NAME
                    </th>
                    <th class="position-relative" :style="{width:'20%'}">
                      ADDRESS
                    </th>
                    <th class="position-relative" :style="{width:'10%'}">
                      TYPE
                    </th>
                    <th class="position-relative" :style="{width:'20%'}">
                      EMAIL
                    </th>
                    <th class="position-relative" :style="{width:'10%'}">
                      PHONE
                    </th>
                    <th class="position-relative" :style="{width:'10%'}">
                      STATUS
                    </th>
                    <th :style="{width:'10%'}">
                      Created At
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(customer) in customers" :key="customer.id">
                    <td>
                      <div>
                        <span class="customer-name" v-if="customer.user">
                          {{ customer.user.full_name }}
                        </span>
                      </div>
                      <div v-if="customer.address && customer.address.business">
                        <small>{{ customer.address.business.name }}</small>
                      </div>
                    </td>
                    <td v-if="customer.address">
                      {{ customer.address.street }}, {{ customer.address.suburb }}
                      {{ customer.address.state.toUpperCase() }} {{ customer.address.post_code }},
                      {{ customer.address.country }}
                    </td>
                    <td>{{ customer.type }}</td>
                    <td>{{ customer.user.email }}</td>
                    <td>{{ customer.user.phone_number }}</td>
                    <td>{{ customer.status }}</td>
                    <td>
                      {{ customer.created_at }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
//component
import AppLayout from '@/layouts/backEnd/AppLayout'

//mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

//core package
import {mapActions, mapGetters} from "vuex";

// package
import VueMultiselect from 'vue-multiselect';
import {DatePicker} from 'v-calendar';

export default {
  name: "CustomerList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    VueMultiselect,
    DatePicker,
  },
  data() {
    return {
      selectedCustomerType: {
        value: '',
        name: 'Any'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      selectedNewsletterSubscriptionStatus: {
        value: '',
        name: 'Any',
      },
      selectedCreatedDateFrom: '',
      selectedCreateDateTo: '',
      selectedState: {
        value: '',
        name: 'Any'
      },
      customerSearchText: '',
      getCustomersParams: {
        type: '',
        status: '',
        newsletter_subscription: '',
        created_at: [],
        where_has_address_state: '',
        where_has_user_search_query: '',
        with_relation: ['user', 'address', 'address.business',],
        order_by_id: 'DESC',
      },
      exportCustomerListParams: {
        type: null,
        status: null,
        newsletter_subscription: null,
        where_has_address_state: null,
        where_has_user_search_query: null,
        created_at: [],
        selected_columns: {
          user: [],
          customer: [],
          address: [],
          business: [],
        },
      },
      getSettingsParams: {
        type: ['default'],
        key: ['default_state', 'default_customer_type'],
      },
    }

  },
  computed: {
    ...mapGetters({
      customers: 'appCustomers/customers',
    }),
    customerTypeOptions() {
      return [
        {
          value: '',
          name: 'Any'
        },
        ...this.$store.getters['appSettings/settingDefaultCustomerType'].value,
      ];
    },
    statusOptions() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ];
    },
    newsletterSubscriptionStatusOptions() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Unsubscribed'
        },
        {
          value: 1,
          name: 'Subscribed'
        },
      ];
    },
    stateOptions() {
      return [{
        value: '',
        name: 'Any'
      }, ...this.$store.getters['appSettings/settingDefaultState'].value];
    },
    hasSelectedColumns() {
      return this.exportCustomerListParams.selected_columns.user.length > 0 ||
          this.exportCustomerListParams.selected_columns.customer.length > 0 ||
          this.exportCustomerListParams.selected_columns.address.length > 0 ||
          this.exportCustomerListParams.selected_columns.business.length > 0;
    }
  },
  watch: {
    selectedCustomerType(selectedCustomerType) {
      this.getCustomersParams.type = selectedCustomerType.value;
      this.exportCustomerListParams.type = selectedCustomerType.value;
    },
    selectedStatus(selectedStatus) {
      this.getCustomersParams.status = selectedStatus.value;
      this.exportCustomerListParams.status = selectedStatus.value;
    },
    selectedNewsletterSubscriptionStatus(selectedNewsletterSubscriptionStatus) {
      this.getCustomersParams.newsletter_subscription = selectedNewsletterSubscriptionStatus.value;
      this.exportCustomerListParams.newsletter_subscription = selectedNewsletterSubscriptionStatus.value;
    },
    selectedCreatedDateFrom(selectedCreatedDateFrom) {
      this.getCustomersParams.created_at[0] = selectedCreatedDateFrom;
      this.exportCustomerListParams.created_at[0] = selectedCreatedDateFrom;
    },
    selectedCreateDateTo(selectedCreateDateTo) {
      this.getCustomersParams.created_at[1] = selectedCreateDateTo;
      this.exportCustomerListParams.created_at[1] = selectedCreateDateTo;
    },
    selectedState(selectedState) {
      this.getCustomersParams.where_has_address_state = selectedState.value;
      this.exportCustomerListParams.where_has_address_state = selectedState.value;
    },
    customerSearchText(selectedState) {
      this.getCustomersParams.where_has_user_search_query = selectedState;
      this.exportCustomerListParams.where_has_user_search_query = selectedState;
    }


  },
  methods: {
    ...mapActions({
      getCustomers: 'appCustomers/getCustomers',
      getSettings: 'appSettings/getSettings',
      postExportCustomerList: 'appCustomers/postExportCustomerList',
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
    async getCustomerList() {
      await this.getCustomers(this.getCustomersParams)
          .then((response) => {
            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }
          });
    },
    async applyCustomerFilter() {
      await this.loader(true);
      await this.getCustomerList();
      await this.loader(false);
    },
    async exportCustomers() {
      await this.loader(true);
      await this.postExportCustomerList(this.exportCustomerListParams).then(async response => {
        await this.loader(false);
        if (response.status === 200) {
          let toastObj = {message: 'Data exported successfully.', type: 'success'};
          await this.showToastMessage(toastObj);
        }

        if (response.message) {
          await this.showToastMessage(response);
        }
      });
    },


  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  }

}
</script>

<style scoped>
.customer-name {
  color: #5A8DEE;
}
</style>
